import React from "react";
import "./Legal.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ProjectsGallery from "../ProjectsGallery/ProjectsGallery";

function Legal(props) {
  return (
    <React.Fragment>
      <Nav />
      <div style={{}} className="legal-container">
        <h1 className="legal-title">Legal</h1>
        <div className="legal-text">
          <p>
            This website is informational only, and is not a solicitation for
            purchase or sale of securities, nor a solicitation for an offer to
            do the same. It does not constitute legal, tax, or investment
            advice. This website is currently hosted for development and
            illustration purposes. As such, statements made herein may be
            preliminary or incomplete, and are not intended for general public
            readership. Luca Ventures Advisors LLC (“LV”) is a fund advisor
            currently in process of organization, and as such neither it nor the
            fund(s) described herein (the “Funds”) are available for investment,
            registered, or otherwise qualified, as may be applicable, with the
            United States Securities and Exchange Commission, FINRA, or any
            other government or regulatory authority of any U.S. federal, state,
            or non-U.S jurisdiction. No such authority has approved, passed on
            or endorsed the merits of any investment opportunity that may be
            described herein, or confirmed or determined the adequacy of this
            presentation. <br></br>
            <br></br>The information herein has not been audited or verified by
            any independent party. LV does not intend to register the Funds as
            investment companies under the U.S. investment company act of 1940,
            and expects that the Funds will be exempt per the venture capital
            provision under the 3(c)(1) exemption thereto. LV further
            anticipates that it will be exempt from reporting or registration
            under the venture capital adviser exemption of the Investment
            Advisers Act of 1940, and corresponding provisions of California
            law. Any offer of interests to the Funds shall be made only by
            formal offering documents to accredited United States investors who
            have been approved as suitable and undergone a “know your client”
            and anti-money laundering review. Said offering documents will
            include among other things a private placement memorandum,
            subscription agreement, and fund operating agreement. Any
            description of the Funds is a preliminary summary of purpose and
            principal business terms, does not purport to be complete, and is
            qualified in its entirety by reference to said offering documents.
            Said interests will be offered and sold in reliance on exemptions
            from the registration requirements of the U.S. securities act of
            1933 and the securities laws of any state or non-U.S. jurisdiction.
            Interests will be subject to restrictions on transferability and
            resale, which include discretionary approval by LV, and may not be
            transferred or resold except as permitted under the securities act
            and applicable state or non-U.S. securities laws pursuant to
            registration or exemption therefrom. Certain information contained
            herein has been obtained from published sources and from third
            parties. <br></br>
            <br></br>Although believed to be reliable, LV assumes no
            responsibility for the accuracy or completeness of such information.
            This site contains business opinions which are expressed as of the
            date hereof and may change as subsequent conditions vary. Certain
            information contained on this site constitutes “forward-looking
            statements”. Actual events, results, and the actual performance of
            the Funds may differ materially from those reflected or contemplated
            in such statements. Prospective should be aware that they will be
            required to bear the financial risks of investment in the interests
            for an extended period of time. There will not be any public market
            for the interests. Investment in the interests is speculative and
            involves significant risks. An investor should understand such risks
            and have the financial ability and willingness to accept them for an
            indefinite period of time and the ability to sustain the loss of its
            entire investment. No assurance can be given that LV will be able to
            implement its investment strategy for the Funds or achieve its
            investment objective or target return. An investor’s investment in
            the Funds should only comprise a portion of the investor’s portfolio
            and should only serve as part of an overall investment strategy. The
            interests are not deposits in, obligations of, or guaranteed by LV,
            are not insured by any governmental or non-governmental agency. Any
            losses by the Funds are borne solely out of investors’ interests in
            the Funds and such losses are generally not borne by LV its
            affiliates. In considering any prior performance or investment
            history information that may be contained in this site, prospective
            investors should understand that such information is neither a
            guarantee nor indicative of the future performance or investment
            returns. Actual events or conditions that impact the Funds’
            performance may not be consistent with, and may differ materially
            from, historical events or conditions.
          </p>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default Legal;
