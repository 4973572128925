import React, { useState, useEffect } from "react";

import { Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./ProjectsGallery.css";
// import galleryItems from "./gallery.json";
// import FA from "react-fontawesome";

import "./ProjectCard.scss";

// import ProjectCard from "./ProjectCard";

function ProjectsGallery({ onSelectProject, currentProject, galleryItems }) {
  const [items, setItems] = useState([]);

  const [clicked, setClicked] = useState(false);

  const checkRole = (role, target) => {
    if (role.includes(target)) {
      return true;
    }
    return false;
  };

  const handleClick = () => {
    console.log("clicked: ");
  };

  function doClick() {
    console.log("hello click");
  }

  useEffect(() => {
    setItems(galleryItems);
    console.log(galleryItems);
  });

  useEffect(() => {
    console.log("clicked!!!");
  }, [clicked]);

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          {items.map((item) => (
            <Col xs={12} sm={6} md={6} lg={3} className="card-col">
              <div className="item">
                <a
                  href={item.link}
                  target="_blank"
                  // onClick={() => onSelectProject(item)}
                  aria-labelledby="person1"
                ></a>
                <img
                  className="project-img-page"
                  src={item.imageLink}
                  alt=""
                ></img>
                <div className="item__overlay">
                  <h3 id="person1" aria-hidden="true">
                    <div
                      className={
                        item.title.length > 17
                          ? "card-text card-text-long"
                          : "card-text"
                      }
                    >
                      {item.title}
                    </div>
                  </h3>
                  <div className="item__body">
                    <p>{item.projectDescription}</p>
                    <button className="learn-more-btn">Learn More</button>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
export default ProjectsGallery;
