import React, { useEffect, useState } from "react";
import "./Work.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ProjectsGallery from "../ProjectsGallery/ProjectsGallery";
import InfoAction from "../InfoAction/InfoAction";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import workImg from "../../resources/portfolio.png";
import shieldImg from "../../resources/shield.png";
import homeImg from "../../resources/home.png";
import shareLottie from "../../resources/lotties/company-growth.json";
import companyLottie from "../../resources/lotties/company-discussion.json";
import investorLottie from "../../resources/lotties/businessmen.json";
import useWindowDimensions from "../LottieTextSection/useWindowDimensions";

function Work(props) {
  const [selectedTab, setSelectedTab] = useState("share");
  const { height, width } = useWindowDimensions();
  const isMobile = width <= 600;

  useEffect(() => {
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var c = url.searchParams.get("service");

    if (c === "investors" || c === "share" || c === "companies") {
      setSelectedTab(c);
    }

    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Nav />
      <div className="container home-page-container secondaries-container">
        <h1 className="my-4 text-center">Secondaries</h1>
        <p className="text-center">
          Select a category to view the services we provide for Investors,
          Shareholders and Companies.
        </p>
        <div className="top-tab-btn-container ">
          <button
            className={
              selectedTab == "investors"
                ? "top-tab-btn top-tab-btn-selected"
                : "top-tab-btn"
            }
            onClick={() => setSelectedTab("investors")}
          >
            Investors
          </button>
          <button
            className={
              selectedTab == "share" ? "top-tab-btn-selected" : "top-tab-btn"
            }
            onClick={() => setSelectedTab("share")}
          >
            Shareholders
          </button>
          <button
            className={
              selectedTab == "companies"
                ? "top-tab-btn top-tab-btn-selected"
                : "top-tab-btn"
            }
            onClick={() => setSelectedTab("companies")}
          >
            Companies
          </button>
        </div>

        {selectedTab === "share" && (
          <>
            <div className="work-lottie-container">
              {isMobile ? (
                <LottieAnimation src={shareLottie} width={350} />
              ) : (
                <LottieAnimation src={shareLottie} width={450} />
              )}
            </div>
            <div className="work-text">
              {/* <h2 className="work-header">Employees and Shareholders</h2> */}

              <p>
                Luca provides liquidity for early employees, founding teams, and
                investors of venture-backed startups. Whether it's paying for a
                life event or a way to diversify wealth, we help early
                stockholders sell their holdings.
              </p>
              <p>
                High exercise costs are a significant pain point for
                shareholders. According to Secfi, it cost the average client
                $543,254 to exercise their stock options.
                <a
                  target="_blank"
                  href="https://www.secfi.com/learn/2021-state-of-stock-options"
                >
                  <sup>1</sup>
                </a>{" "}
                That price can increase 3,360% between the Seed and Series C
                funding stages.
              </p>
              <p>
                Employees who leave their company have a limited amount of time
                to exercise their options before they expire. Unfortunately, the
                affordability factor prohibits many from participating in the
                future growth of their startup.{" "}
              </p>
              <p>
                Securing buyers is a solution to lock in gains, fund option
                exercises, and provide for other opportunities.
              </p>
              <p className="subfooter">
                <sup>1</sup>{" "}
                <a
                  className="work-link"
                  target="_blank"
                  href="https://www.secfi.com/learn/2021-state-of-stock-options"
                >
                  Secfi
                </a>
              </p>
              {/* <p>
                Whether it's paying for a life event or a way to diversify net
                worth, Luca helps employees capitalize on their equity
                compensation.
              </p> */}
              {/* <li>
              <a
                target={"_blank"}
                href="https://www.businesswire.com/news/home/20201217005696/en/Startup-Employees-Left-4.9-Billion-On-The-Table-By-Not-Exercising-Stock-Options-Secfi-Publishes-State-of-Stock-Options-Report"
              >
                Startup employees left $4.9 billion on the table
              </a>{" "}
              by not exercising their pre-IPO stock options.
            </li>
            <li>
              On average, the cost to exercise stock options is $500k, nearly 2x
              the annual Household Income of company employees.
            </li>
            <li>
              85% of exercise costs are tax, making exercising 6.6x more
              expensive than the options themselves.
            </li>
            <li>
              For companies with a 409a of $10B+, the exercise cost skyrockets
              to 5.5x annual Household Income.
            </li>
            <h3 className="work-subheader">Solution</h3>
            <p>
              Alleviate the pain of buying and selling private equities by an
              intermediate entity between sellers and investors of private
              companies.
            </p> */}
            </div>
          </>
        )}
        {selectedTab === "companies" && (
          <div className="work-text">
            {/* <h2 className="work-header">
              Companies and Special Purpose Vehicles
            </h2> */}
            {isMobile ? (
              <LottieAnimation src={companyLottie} width={350} />
            ) : (
              <LottieAnimation src={companyLottie} width={450} />
            )}
            <br />
            <br />
            <p>
              Luca creates and manages Special Purpose Vehicles (SPVs) that each
              operate as a single, professionally-managed stockholder on a
              company’s capitalization table. This allows us to pool shares from
              multiple sellers on behalf of one or more investors, ranging from
              ultra high net worth individuals and family offices to
              institutional funds. Each subscriber is vetted to meet our
              standards and those of issuers. We operate our funds over time,
              generally holding the shares until a company’s IPO or other
              liquidity event. With in-house legal and compliance experience
              managing dozens of SPVs, and many billions of dollars of late
              stage venture investments and secondary transactions, we can
              tailor investment structures to fit investor and issuer needs.
            </p>
            <p>
              With in-house legal expertise and substantial involvement managing
              dozens of SPVs, we can tailor investment structures to fit
              investor needs. Our partners have helped facilitate over $2b of
              late-stage venture investments bringing years of secondary
              experience to Luca.
            </p>
            {/* <div className="spv-header">
              <h3>Our SPVs</h3>
            </div>
            <ProjectsGallery /> */}
          </div>
        )}
        {selectedTab === "investors" && (
          <div className="work-text">
            {/* <h2 className="work-header">Institutional Investors</h2> */}
            <LottieAnimation src={investorLottie} width={350} />
            <p>
              <br></br>
              We provide institutional investors access to pre-IPO company
              shares through special-purpose investment funds and affiliated
              broker-dealers.
            </p>
            <p>
              In 2021, investors poured $621 billion into startups. Although
              this is a 11% increase
              <a
                target="_blank"
                href="https://www.cbinsights.com/research/report/venture-trends-2021/"
              >
                <sup>1</sup>
              </a>{" "}
              from 2021, it is still dwarfed by the $3.37 trillion total
              cumulative valuation of unicorns worldwide.
            </p>
            <p>
              Navigating the private markets can be difficult. Luca is on a
              mission to democratize access.
            </p>
            <p className="subfooter">
              <sup>1</sup>{" "}
              <a
                className="work-link"
                target="_blank"
                href="https://www.cbinsights.com/research/report/venture-trends-2021/"
              >
                CB Insights
              </a>
            </p>
          </div>
        )}

        {/* <InfoAction /> */}
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Work;
