import React from "react";
import "./Team.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

import michaelImg from "../../resources/michael.jpg";
import gilImg from "../../resources/gil.jpeg";
import jpImg from "../../resources/jp.webp";
import LottieTextSection from "../LottieTextSection/LottieTextSection";
import moneyStack from "../../resources/lotties/money-stack.json";
import safeDiamond from "../../resources/lotties/safe-diamond.json";
import InfoAction from "../InfoAction/InfoAction";

import ScrollAnimation from "react-animate-on-scroll";

function Team(props) {
  return (
    <React.Fragment>
      <Nav />
      <div className="container home-page-container team-container">
        <h1 className="my-4 text-center mb-5">Our Team</h1>
        <ScrollAnimation animateIn="fadeIn" duration={2}>
          <div className="position-relative overflow-hidden text-center text-light ">
            <div className="row">
              <div className="col-lg-12">
                <div className="category-img-container-2">
                  <img
                    src={gilImg}
                    alt="Category"
                    className="category-img-2"
                    style={{ marginTop: "5px" }}
                  />
                </div>
                <a
                  href="https://www.linkedin.com/in/gilsilberman/"
                  className="fa fa-linkedin social"
                  target={"_blank"}
                ></a>
                <h2 className="about-job-header">Gil Silberman</h2>
                <p className="about-description team-text indent">
                  Gil is Co-Founder and Managing Partner of Luca Ventures,
                  responsible for operations and seed stage investments. He has
                  an extensive background in startup law, venture finance,
                  investing, and business. Immediately before founding Luca
                  Ventures, Gil founded and served as Chief Legal Officer for
                  Forge Global, a leading marketplace for equity in private
                  companies, where he oversaw the setup and operation of SPVs to
                  hold pre-IPO stock of venture-funded companies. Forge now has
                  tens of billions of dollars under management and
                  custodianship. Gil has also served as a partner at San
                  Francisco Arts Fund, and founding lawyer of S2 Capital, an
                  early-stage venture fund, as well as founder, Managing
                  Partner, and manager of the venture operations of Britton
                  Silberman Cervantez, a startup and venture finance law firm
                  that was first lawyers to Craigslist, OpenTable, LinkedIn,
                  On24, and hundreds of other startups, and later became the
                  Emerging Companies Practice of Thelen Reid and Priest. Gil
                  graduated from Princeton, where he studied Electrical
                  Engineering and Computer Science, as well as Berkeley Law.
                </p>
                {/* <p className="about-description team-text indent">
                  Immediately before founding Luca Ventures, Gil founded and
                  served as Chief Legal Officer for Forge Global, a leading
                  marketplace for equity in private companies, where he oversaw
                  the setup and operation of SPVs to hold pre-IPO stock of
                  venture-funded companies. Forge now has tens of billions of
                  dollars under management and custodianship. Gil has also
                  served as a partner at San Francisco Arts Fund, and founding
                  lawyer of S2 Capital, an early-stage venture fund, as well as
                  founder, Managing Partner, and manager of the venture
                  operations of Britton Silberman Cervantez, a startup and
                  venture finance law firm that later became the Emerging
                  Companies Practice of Thelen Reid and Priest. Gil graduated
                  from Princeton, where he studied Electrical Engineering and
                  Computer Science, as well as Berkeley Law.
                </p> */}
              </div>
              <div className="col-lg-12">
                <div className="category-img-container-2">
                  <img src={michaelImg} alt="Home" className="category-img-2" />
                </div>
                <a
                  href="https://www.linkedin.com/in/michael-grigoriev-446a39101/"
                  className="fa fa-linkedin social"
                  target={"_blank"}
                ></a>
                <h2 className="about-job-header">Michael Grigoriev</h2>
                <p className="about-description team-text indent">
                  Michael is Co-Founder and Managing Partner of Luca Ventures,
                  responsible for secondary private stock trading and investing.
                  He was previously a Senior Manager of Corporate Partnerships
                  at Plug and Play, a leading technology accelerator, where he
                  helped manage the corporate innovation accelerator for the
                  Travel and Hospitality vertical. While at Plug and Play,
                  Michael facilitated hundreds of dealflow sessions between
                  startups and Fortune 500 corporations looking to invest,
                  pilot, license, or acquire new technologies. Michael graduated
                  from the University of Colorado Boulder, where he studied
                  Information Science and Economics.
                </p>
              </div>
              <div className="col-lg-12">
                <div className="category-img-container-2">
                  <img src={jpImg} alt="Shield" className="category-img-2" />
                </div>
                <a
                  href="https://www.linkedin.com/in/john-paul-teutonico-b121515/"
                  className="fa fa-linkedin social"
                  target={"_blank"}
                ></a>
                <h2 className="about-job-header">John-Paul Teutonico</h2>
                <p className="about-description team-text indent">
                  JP is Head of Compliance of Luca Ventures. He has worked with
                  Gil as first employee and Chief Compliance Officer of Forge
                  Global from 2014 through 2021. Before Forge, JP was on the
                  founding team of SecondMarket, an earlier pre-IPO investment
                  company, where he served as Chief Administrator, responsible
                  for all operations, transaction settlements, and compliance.
                  Prior to SecondMarket he served in the Single Stock Risk
                  Management group of Goldman Sachs, Inc. where he managed all
                  of the group's restricted securities executions through both
                  open market and privately-negotiated transactions. Prior to
                  Goldman Sachs, he was an Associate at Bear, Stearns & Co. JP
                  graduated with a B.A. from Lafayette College.
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
      {/* <InfoAction /> */}

      <Footer />
    </React.Fragment>
  );
}

export default Team;
