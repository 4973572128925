import React from "react";
// import About from "../components/About/About";
import Home from "../components/Home/Home";
import Contact from "../components/Contact/Contact";
import Work from "../components/Work/Work";
import VentureInvestments from "../components/VentureInvestments/VentureInvestments";
import Team from "../components/Team/Team";
import Legal from "../components/Legal/Legal";
import Privacy from "../components/Legal/Privacy";

const routes = {
  "/": () => <Home />,
  // "/about": () => <About />,
  "/contact": () => <Contact />,
  "/venture-investments": () => <VentureInvestments />,
  "/work": () => <Work />,
  "/team": () => <Team />,
  "/legal": () => <Legal />,
  "/privacy": () => <Privacy />,
};
export default routes;
