import React from "react";
import "./Home.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";

// import ScrollAnimation from "react-animate-on-scroll";
import Wave from "react-wavify";
// import workImg from "../../resources/portfolio.png";
// import shieldImg from "../../resources/shield.png";
// import homeImg from "../../resources/home.png";

import LottieTextSection from "../LottieTextSection/LottieTextSection";
import moneyStack from "../../resources/lotties/money-stack.json";
import safeDiamond from "../../resources/lotties/safe-diamond.json";
import wealthLottie from "../../resources/lotties/investment-growth.json";
import contactLottie from "../../resources/lotties/phone-message.json";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import capitalInvestmentLottie from "../../resources/lotties/capital-investment.json";
import upchartCompany from "../../resources/lotties/upchart-company.json";
import ventureInvestmentLottie from "../../resources/lotties/venture-investments.json";
import useWindowDimensions from "../LottieTextSection/useWindowDimensions";
// import headerLottie from "../../resources/lotties/money-stack.json";
// import financeLottie from "../../resources/lotties/finance-header.json";
// import ProjectsGallery from "../ProjectsGallery/ProjectsGallery";
// import InfoAction from "../InfoAction/InfoAction";

// import financeLottie from "../../resources/lotties/finance-guy.json";
function Home(props) {
  const { height, width } = useWindowDimensions();

  const isMobile = width <= 600;
  return (
    <React.Fragment>
      <Nav />

      <div className="home-page-container">
        {/* <h1 className="my-4 text-center">Luca Ventures</h1>
        <p className="text-center">
          Luca Ventures is a Growth Stage Venture Capital firm that focuses on
          pre-IPO, secondary investments.
        </p> */}

        <div className="home-header-section">
          <div className="header-left">
            <h1 className="header-title">Luca Ventures</h1>
            <p className="header-subtitle">
              Bringing liquidity to private markets.
            </p>
            <a href="/contact" className="get-in-touch-btn-2">
              Get in Touch
            </a>
          </div>
          <div className="header-right">
            {isMobile ? (
              <LottieAnimation src={capitalInvestmentLottie} width={width} />
            ) : (
              <LottieAnimation
                src={capitalInvestmentLottie}
                width={400}
                height={400}
              />
            )}
          </div>
        </div>
        {/* <div className="position-relative overflow-hidden text-center text-light services-container">
          <div className="row">
            <div className="col-lg-4">
              <div className="category-img-container-1">
                <img src={workImg} alt="Category" className="category-img-1" />
              </div>
              <h2 className="about-job-header">Special Purpose Vehicles</h2>
              <p className="about-description">
                Best in class service, availability, and knowledge for all of
                your mortgage needs. Know your loan’s status 24/7 and never be
                left guessing what the next step is.
              </p>
            </div>
            <div className="col-lg-4">
              <div className="category-img-container-1">
                <img src={homeImg} alt="Home" className="category-img-1" />
              </div>
              <h2 className="about-job-header">Mortgage Services</h2>
              <p className="about-description">
                Consult with a trusted professional who excels at helping people
                understand and navigate the mortgage market. Take advantage of
                these historically low rates and know every cost upfront.
              </p>
            </div>
            <div className="col-lg-4">
              <div className="category-img-container-1">
                <img src={shieldImg} alt="Shield" className="category-img-1" />
              </div>
              <h2 className="about-job-header">Strong Ethics</h2>
              <p className="about-description">
                Feel safe working with someone who’s driven by honesty,
                integrity, and family values. Get the genuine loan experience
                you've been looking for.
              </p>
            </div>
          </div>
        </div> */}

        {/* <Wave
          fill="#8a74ea"
          paused={false}
          options={{
            height: 20,
            amplitude: 30,
            speed: 0.15,
            points: 3,
          }}
        />

        <div className="wave-gradient"></div> */}

        <LottieTextSection
          title={<div className="about-title">Secondaries</div>}
          textContent={
            <div className="about-text">
              <p>
                <b>Shareholders: </b>
                liquidity for early employees, founding teams, and investors in
                venture-backed startups.
              </p>
              <p>
                <b>Institutional investors: </b>
                access to investment opportunities in pre-IPO companies through
                special-purpose investment funds and affiliated broker-dealers.
              </p>
              <p>
                <b>Companies: </b>
                liquidity to retain your best employees without giving up
                additional company equity.
              </p>
            </div>
          }
          lottieLeftBool={false}
          lottie={wealthLottie}
          lottieWidth={400}
          // learnMoreLink={"./venture-investments"}
          // buttonTitle="View Investments"
        />

        <LottieTextSection
          title={
            <div className="about-title">
              <br></br>The Process
            </div>
          }
          // color="#8a74ea"
          textContent={
            <div className="about-text">
              <ul className="ul-test">
                <b>Special-purpose vehicle funds (SPVs):</b>
                <p>
                  <i>
                    Luca manages intermediate entities between sellers and share
                    purchasers of private companies.
                  </i>
                </p>
                <div className="list">
                  <li>
                    The transaction process is handled from end to end by our
                    team. From finding employees needing liquidity, to working
                    with companies through the necessary steps such as rights of
                    first refusal, board approval, and completion of transfer
                    documents. In some cases we can arrange loans and other
                    facilities for holders to exercise options so that they may
                    sell their shares.
                  </li>
                  <li>
                    SPVs are managed by Luca until there is a liquidity event.
                    The stock is distributed to investors after the company goes
                    public (or sales proceeds, if the company is sold).
                  </li>
                </div>
              </ul>

              <ul className="ul-test">
                <b>Direct Transfers</b>
                <p>
                  <i>
                    For larger transactions, Luca tailors solutions for
                    investors that are qualified and approved to own shares
                    directly or through their own vehicles.
                  </i>
                </p>
                <div className="list">
                  <li>
                    We may offer co-management rights, single-subscriber funds,
                    or seek a direct transfer of shares from shareholders to
                    investors.
                  </li>
                  <li>
                    Luca provides coordination and back-office support for stock
                    purchase transactions.
                  </li>
                  <li>
                    Because we are not brokers, we will refer negotiation and
                    closing of transactions to an affiliated broker dealer.
                  </li>
                </div>
              </ul>
            </div>
          }
          lottieLeftBool={true}
          lottie={ventureInvestmentLottie}
          width={500}
        />

        <div className="contact-section">
          <LottieTextSection
            title={<div className="about-title">Contact</div>}
            // color="#8a74ea"
            textContent={
              <p className="about-text text-center">
                Get in touch with our team to learn more.
              </p>
            }
            lottieLeftBool={false}
            lottie={contactLottie}
            learnMoreLink={"/contact"}
            buttonTitle="Contact Us"
          />
        </div>

        {/* <ScrollAnimation animateIn="fadeIn" duration={2}>
          <div className="spv-header">
            <h2>Luca Managed SPVs</h2>
          </div>
          <ProjectsGallery />
        </ScrollAnimation> */}
      </div>
      {/* <InfoAction /> */}
      <Footer />
    </React.Fragment>
  );
}

export default Home;
