import React from "react";
// import "bootstrap/dist/css/bootstrap.css";
import "./Footer.css";
import crunchBaseLogo from "../../resources/logos/crunchbase-logo.png";
import logo from "../../resources/lv-graphic-logo-2.png";

function Footer(props) {
  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="footer-wrapper">
          <img
            src={logo}
            className="nav-logo"
            style={{ marginRight: 15, width: 25 }}
          />

          <p className="navbar-brand">Luca Ventures</p>

          <p>
            <a href="tel:702-403-6473" className="footer-link-luca">
              (702) 403-6473
            </a>
            <br></br>
            <a href="mailto:contact@luca.vc" className="footer-link-luca">
              contact@luca.vc
            </a>
          </p>

          <div className="text-center fa-size">
            {/* the following {} should be kept due to achor tags requiring content */}
            <a
              href="https://www.linkedin.com/company/lucavc"
              className="fa fa-linkedin social"
              target={"_blank"}
            >
              {}
            </a>

            <a
              href="https://www.crunchbase.com/organization/luca-ventures-f24f"
              target={"_blank"}
            >
              <img className="cb-logo" src={crunchBaseLogo} />
            </a>
          </div>
          <p>
            <a href="/legal" className="footer-link-luca">
              Legal
            </a>
            <br></br>
            <a href="/privacy" className="footer-link-luca">
              Privacy Policy
            </a>
          </p>

          <p className="m-0 text-center text-black">
            Copyright &copy; 2020-2022 Luca Ventures
          </p>

          {/* <p className="powered-text">
            Powered by{" "}
            <a
              href="https://cloudsurfsoftware.com/"
              className="outerlink"
              target="_blank"
              rel="noopener noreferrer"
            >
              CloudSurf
            </a>
          </p> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
