import React from "react";
import "./VentureInvestments.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ProjectsGallery from "../ProjectsGallery/ProjectsGallery";
import lvLaunchJson from "./lv-launch.json";
import s2Json from "./s2-capital.json";
import forgeJson from "./forge.json";
import historicJson from "./historic.json";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import ventureInvestmentLottie from "../../resources/lotties/venture-investments.json";
import useWindowDimensions from "../LottieTextSection/useWindowDimensions";

import lottie from "../../resources/lotties/upchart-company.json";

function VentureInvestments(props) {
  const { height, width } = useWindowDimensions();

  const isMobile = width <= 600;
  return (
    <React.Fragment>
      <Nav />
      <div className="venture-body">
        {/* <div className="venture-lottie">
          <LottieAnimation
            src={ventureInvestmentLottie}
            width={400}
            height={400}
          />
        </div> */}
        <h1 className="venture-title">Venture Investments</h1>
        <div className="awk-lottie">
          {isMobile ? (
            <LottieAnimation loopBool={false} src={lottie} width={350} />
          ) : (
            <LottieAnimation loopBool={false} src={lottie} width={450} />
          )}
        </div>
        <div className="venture-text">
          <p>
            Luca Ventures is a fintech venture fund that leverages industry
            connections and a founder-driven investment strategy. We have a
            regulatory thesis, seeking enterprise-facing companies that need our
            advice and mentorship to acquire their first licenses, permits, and
            compliance infrastructure. We seek small allocations in well
            subscribed pre-seed rounds. In exchange we buy SAFE instruments and
            convertible notes in 30-40 portfolio companies alongside their other
            first supporters while offering a value-add well beyond the cash
            invested. By participating early we support companies as they strive
            towards billion dollar valuations.{" "}
          </p>

          <p>
            From the outside the Launch is a typical venture fund with limited
            partners subscribing to an interest in the fund, and Lucca Ventures
            serving as Investment Advisor in exchange for a standard 2 and 20
            management fee and carry. Inside, a sophisticated fund structure
            inspired by years of managing large private equity funds is designed
            for early liquidity from time to time, and co-investment
            opportunities at the option of key limited partners.{" "}
          </p>
        </div>
        <h2>Disruption and Consolidation</h2>
        <div className="venture-text">
          <p>
            The world's financial systems and the technology behind them were
            built in the 1970s and before. Banking, investing, insurance, wealth
            management, trading, securitization, billing, payments, credit, and
            asset ownership all rely on antiquated tools and practices.
            Collectively, they are one of the last major industries to face
            upheaval from technological progress. The old ways are not
            especially fast, safe, or reliable. They are not flexible and they
            inhibit change. But they have worked well enough to serve their
            purpose, and have not been worth replacing, given the vast cost of
            reengineering the economy. Today, a new generation of startup
            founders is ready to redesign its infrastructure tools. As happened
            in other industries we expect a 5-8 year period of widespread
            fragmentation and unbundling, followed by consolidation around
            massive new companies. Legacy companies that adapt may survive and
            may sponsor these efforts. Those that cannot will be disrupted. We
            believe that now is the time to get in, with the economy down for
            maintenance and with future market leaders as yet unidentified and
            unproven.
          </p>
        </div>

        <h3>We Seek</h3>
        <div className="venture-text">
          <ul>
            <li>
              exchanges, platforms, tools, applications, infrastructure,
              software, operations
            </li>
            <li>
              corporation as a service, cap table management, asset management
            </li>
            <li>payments, transfers, billing, settlement</li>
            <li>security + digital identity</li>
            <li>Regulatory compliance + regtech</li>
            <li>Risk analysis and management</li>
          </ul>
        </div>
        <h3>We Avoid</h3>
        <div className="venture-text">
          <ul>
            <li>
              Principal positions in loans, insurance risk, securities, hedges,
              real estate, alternative assets, currency{" "}
            </li>
            <li>
              Regulated and non-tech brokers, dealers, traders, banks, funds,
              wealth advisors
            </li>
            <li>Media</li>
            <li>Referral networks</li>
            <li>Consultancies, professional services</li>
          </ul>
        </div>
        <h2>Portfolio Companies</h2>

        <h3>LV Launch</h3>
        <div className="venture-text">
          <p>
            Launch targets 30-40 seed and pre-seed startups where we can get
            personally involved, and outperform expectations as an investor. As
            of July 2021 it is approximately 25% deployed.{" "}
          </p>
        </div>
        <ProjectsGallery galleryItems={lvLaunchJson} />

        <h3 className="s2capital">S2 Capital</h3>
        <div className="venture-text">
          <p>
            S2 Capital is a seed investor and AngelList syndicate specializing
            in Y Combinator companies, alumni, and friends. We setup S2 Capital
            in 2014, with Sohail and Samvit operating separately from Luca
            Ventures.
          </p>
        </div>

        <ProjectsGallery galleryItems={s2Json} />

        <h3 className="s2capital">Forge Investments</h3>
        <div className="venture-text">
          <p>
            In 2014 Gil, Sohail, and Samvit founded Forge, with JP, our first
            employee, managing compliance.{" "}
          </p>
          <p>
            Forge is a private equity marketplace and services company that has
            transacted more than $6 billion of securities trades. It has over
            $11 billion AUM.
          </p>
          <p>
            Among other investments, Forge funds acquired more than $300 million
            in pre-IPO unicorn company stock, mostly in secondary markets
            (buying from existing stockholders rather than investing directly in
            the companies).
          </p>
        </div>
        <ProjectsGallery galleryItems={forgeJson} />

        <h3 className="s2capital">Historic Ventures</h3>
        <div className="venture-text">
          <p>
            Here are some other holdings the team acquired in their personal
            capacities. They've also held early stage roles in companies such as
            Craigslist, LinkedIn, Zynga, and Chartboost.
          </p>
        </div>
        <ProjectsGallery galleryItems={historicJson} />
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default VentureInvestments;
