import React, { useState, useEffect } from "react";
import "./Contact.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import { checkValidEmail, checkValidName } from "../../services/util";

function Contact(props) {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSendingAlert, setShowSendingAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const defaultErrorMessage = "Something went wrong...";
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

  useEffect(() => {
    setShowFailAlert(false);
  }, [name]);
  useEffect(() => {
    setShowFailAlert(false);
  }, [email]);

  const handleSubmit = () => {
    const templateId = "kbd_contact_form";

    let validEmail = checkValidEmail(email);
    let validName = checkValidName(name);

    if (validEmail && validName) {
      setShowFailAlert(false);
      setErrorMessage(defaultErrorMessage);
      sendFeedback(templateId, {
        user_message: message,
        user_name: name,
        user_company_name: companyName,
        user_email: email,
        client_email: "michael@luca.vc",
        client_name: "Luca Ventures Team",
      });

      setName("");
      setCompanyName("");
      setEmail("");
      setMessage("");
    } else {
      console.log("setting error message?");
      if (!validEmail) {
        setErrorMessage("Invalid Email Address");
      }
      if (!validName) {
        setErrorMessage("Name cannot be empty");
      }
      console.log("showing fail alert");
      setShowFailAlert(true);
    }
  };

  const sendFeedback = (templateId, variables) => {
    setShowSendingAlert(true);
    window.emailjs
      .send("cs_gmail", templateId, variables)
      .then((res) => {
        console.log("Email successfully sent!");
        setShowSuccessAlert(true);
        setShowSendingAlert(false);
        setTimeout(function () {
          setShowSuccessAlert(false);
        }, 3000);
      })
      .catch((err) => {
        setShowFailAlert(true);
        setTimeout(function () {
          setShowFailAlert(false);
        }, 3000);
        console.error(
          "Oh well, you failed. Here some thoughts on the error that occured:",
          err
        );
      });
  };

  return (
    <React.Fragment>
      <Nav />
      <div className="container contact-container">
        <div className="content-wrapper">
          <h1 className="mt-2 mb-3 text-center">Contact Luca Ventures</h1>
          <div className="text-center contact-h2-container">
            <p className="contact-h2">
              If you are an investor looking for pre-IPO investments, an
              employee shareholder of a pre-IPO company or a company interested
              in our process, please get in touch with our team.
            </p>
          </div>
          {/* <div className="row"></div> */}
          <div className="contact-form text-left">
            <form>
              <div className="form-group">
                <label htmlFor="">Full Name:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Company Name:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Email:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="">Message:</label>
                <textarea
                  rows="10"
                  cols="100"
                  className="form-control"
                  required
                  maxLength="999"
                  style={{ resize: "none" }}
                  placeholder="Add Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>

              {showSuccessAlert && (
                <div className="alert success-alert">
                  <p>Email successfully sent!</p>
                </div>
              )}

              {showSendingAlert && (
                <div className="alert sending-alert">
                  <p>Sending Email</p>
                </div>
              )}

              {showFailAlert && (
                <div className="alert fail-alert">
                  <p>{errorMessage}</p>
                </div>
              )}

              <input
                type="button"
                value="Submit"
                className="get-in-touch-btn"
                style={{
                  width: "100%",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "large",
                }}
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
export default Contact;
