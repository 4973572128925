import React from "react";
import "./Legal.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ProjectsGallery from "../ProjectsGallery/ProjectsGallery";

function Privacy(props) {
  return (
    <React.Fragment>
      <Nav />
      <div style={{}} className="legal-container">
        <h1>Privacy Policy</h1>
        <h2>
          Luca Ventures Advisors LLC ("LV") Privacy Policy - Version 1911 -
          November 18, 2019
        </h2>
        <div className="legal-text">
          <p>
            Privacy and personal information are important to LV, and we take it
            seriously. The website luca.vc (the "Site") and associated online
            services (the "Services") are operated by Luca Ventures Advisors LLC
            and is offered to provide information about our business. Site
            visitors may use the Site to learn about us, our business, and our
            investment activity, as well as to contact us and to provide us
            information about their businesses. This Privacy Policy sets forth
            LV's policy with respect to any information visitors may provide us,
            including personally identifiable information ("Personal
            Information") as well as information that identifies their business
            ("Business Information"). It explains how we collect, use, process,
            and transfer Personal Information and Business Information. By
            accessing the Site, using the Services, or agreeing to provide
            Personal Information and any nonpublic Business Information to LV
            (collectively, your "Private Information") via the Site or the
            Services, you consent to our collection, use, processing, storage,
            and transfer of your Private Information in accordance with this
            Privacy Policy. <br></br>
            <br></br> We will not use, process, or transfer your Private
            Information except as described in this Privacy Policy. If you are
            using the Site or Services on behalf of a business organization or
            other entity, or a client (such as a fund or holding company you may
            manage or advise, a trust, or a joint account), this Privacy Policy
            is nevertheless personal to you as an individual, and does not apply
            to that organization, entity, or client or any information relating
            to that organization, entity or client. For the avoidance of doubt,
            aggregated or anonymized data is no longer considered Private
            Information for the purposes of this Privacy Policy, if it no longer
            directly or indirectly reveals your identity. or that of your
            business. This Privacy Policy is intended to meet our obligations
            and duties relating to private personal information under the legal
            and regulatory requirements of the jurisdictions in which we
            operate, including but not limited to the European Union and the
            State of California, USA. Should you, or a company with which you
            are associated, enter discussions with LV or affiliates in
            connection with a potential securities or other transaction with LV,
            including among other things an investment in, or by, an LV-advised
            fund, this Privacy Policy does not apply to any information supplied
            by you or such entities directly by telephone, in person, online or
            electronic mail or messaging, or otherwise not via Site or Services.{" "}
            <br></br>
            <br></br>
            Such discussions and transactions, including any negotiations and
            due diligence associated therewith, shall not be deemed par tof the
            Services, and LV's confidentiality and privacy obligations with
            respect to such information, if any, shall be exclusively as agreed
            to in connection with such discussions and transactions. We will
            post any updates to this Privacy Policy on this page. f at any time
            you do not agree with this Privacy Policy, please discontinue your
            use of the Site or Services. How and why we collect Personal
            Information We collect Private Information from and about you and
            your business to: Correspond with you, and otherwise enable and
            facilitate your usage of the Site or Services. Provide information
            to you about LV, the Site, and the Services., and facilitate your
            further participation therein. Learn more about you, and any
            business you are associated with, including introductions and
            Business Information useful to LV in connection with its investment
            activities.. Verify the status of persons as professional investors,
            accredited investors (or other qualifications), if applicable;
            process due diligence reviews, and evaluation your eligibility and
            suitability, and those of your company. Consider potential
            investment transactions by LV-advised funds (including, potentially,
            investing in competitors and companies other than yours). <br></br>
            <br></br> Perform industry research and analysis. Facilitate your
            and our compliance with various laws, regulations, and industry
            oversight pertaining among other things to financial information,
            securities transactions, money laundering, terrorism, and “know your
            customer” rules promulgated by, among others, applicable government
            and regulatory bodies. Study, test, and improve the Site and
            Services, and the overall user experience using the Site and
            Services. Offer customer support and maintenance. Protect against
            fraud, Site misuse, privacy and security breaches, securities and
            other legal and regulatory violations, and to fulfill and enforce
            agreements reached with you and others. We ensure that all uses of
            your Personal Information have one of the foregoing legal bases:
            Personal Information (i) that is necessary to provide the Services,
            including customer support, personalized features, and safety and
            security protections, (ii) for which we have a legitimate interest
            not overridden by your data protection interest, such as research
            and development, marketing, and protecting our interests, (iii) to
            comply with our legal and regulatory obligations, or (iv) where you
            have given prior consent to such collection and use, which you may
            withdraw with respect to further processing. We collect information
            by asking you to enter Private Information into forms, by observing
            your account activity and usage of the Services, by logging your
            access and activity on the Site, by your uploading of documents, and
            via third party information providers that, among other things,
            provide background checks to verify your identity, creditworthiness,
            and your eligibility and suitability to participate in Transactions.{" "}
            <br></br>
            <br></br>
            Third party information providers include, among others: credit
            reporting agencies, social media sites, our affiliated entities,
            fund administration partners, advertising platforms, data brokers
            and other data sources, and compliance service providers. If
            information concerning you and your business (including Private
            Information) is available on third party sites or applications such
            as AngelList, Facebook, CrunchBase, or LinkedIn, we may collect
            information from them, potentially including Private Information. We
            may retain or delete your Personal Information according to our
            internal data retention policies as permitted by applicable laws and
            regulations, even after you no longer use the Site and Services or
            terminate or deactivate your account or your relationship with LV,
            among other things for backup, archival, contract enforcement and
            dispute resolution, auditing, and regulatory compliance purposes.
            Types of Personal Information that we collect Your name and contact
            information. Financial information with respect to investment
            objectives and means, including information about investors' wealth
            and financial situation. <br></br>
            <br></br> Site and Service usage history, as well as records of your
            communications with LV and customer service requests. Information
            requested or required by regulatory or governmental authorities or
            law enforcement bodies. In connection with potential transactions by
            or with LV, we gather documents pertaining to companies, including
            any information you choose to provide or documents you choose to
            upload. The types of documents we ask for include pitch decks,
            product demos and videos, financial information, biographical
            information,. Additional information we ask for may include personal
            biographical information, personal equity ownership, company history
            and your relationship with the company, market analysis, revenues
            and other company financial information, and company funding and
            capitalization data. Behavioral and marketing information such as
            your interests, and your public social media presence. If you
            introduce other parties to LV, we may retain Personal Information
            related to your referral. Our online software also automatically
            collects information from your computer, browser, phone, or other
            devices and software used to access the Services, and logs this
            information in log files. This information includes among other
            things your device ID, ISP, IP address, user IDs and access tokens
            for third party sites such as AngelList, Facebook, and LinkedIn,
            information you have authorized a third party to share, and any
            information you have made public on those services. <br></br>
            <br></br> We generally do not collect Personal Information about
            your race, ethnicity, religious or philosophical beliefs, sexual
            orientation, health information, trade union memberships, etc., and
            will not do so without asking for your further, explicit consent.
            Your Rights regarding Personal Information You have the right to do
            the following: Request to access your Personal Information and
            obtain information regarding the processing of your Personal
            Information, subject to applicable laws and regulations, including
            transferring to you or to a third party you choose, a copy of any
            automated Personal Information you provided consent for us to use,
            in a structured, commonly used, machine-readable format, in
            accordance with applicable legal and regulatory requirements.
            Correct your Personal Information, including any incomplete or
            inaccurate information, subject to applicable laws and regulations.
            Erase your Personal Information in certain circumstances (e.g. where
            there is no good reason for us to continue using it). Object to or
            request that we restrict our processing of your Personal
            Information, including deleting or removing it, even in cases where
            we have a good reason to use it, based on something particular to
            your situation, subject to our legal and regulatory obligations as
            described above. <br></br>
            <br></br> Withdraw any consent you have previously given to us with
            respect to the Personal Information. Opt out from direct marketing
            at any time and without charge via the opt-out links on any
            marketing message sent to you, or by contacting us directly. Require
            us, at any time and without charge, to cease to provide your
            Personal Information to any other person for use by that other
            person for direct marketing and to notify any person to whom your
            Personal Information has been so provided to cease to use such
            Personal Information for direct marketing. Ascertain our policies
            and practices in relation to Personal Information and be informed of
            the kind of Personal Information held by us as well as the main
            purposes for which Personal Information held by us is or is to be
            used. Depending on your jurisdiction, you may have the right to
            lodge a complaint with applicable regulatory bodies if you think
            that any of your rights relating to your Personal Information has
            been infringed by us. The above functions should be available either
            on the Site, or else by contacting us at the addresses stated in
            this Privacy Policy. Please note that we have the right to charge a
            reasonable fee for the processing of any Personal Information access
            request, to the extent permitted under local laws and regulations.
            Note, however, that securities law and regulations, among other
            things, may require that we keep an archival / recordkeeping copy of
            some or all of your Personal Information and Business Information,
            or that we provide such information to regulatory authorities,.
            Parties with whom we share Private Information Like any company, we
            may share Private Information that we have collected with our
            affiliates and third parties (whether located within or outside your
            jurisdiction) for the purposes described above. This includes: The
            other party or parties to a prospective or actual transaction
            (including, among other things, interest holders in LV funds).{" "}
            <br></br>
            <br></br>
            Intermediate entities such as funds and “special purpose vehicles”
            that handle securities pools on behalf of LV or on their own
            account, as well as the organizers, managers, and fund advisers of
            these entities or other parties in order to complete, service and
            enforce the terms of a transaction or contemplated transaction. We
            may create, acquire, or otherwise partner with parent, subsidiary,
            or affiliate business entities, trusts, or other business entities
            for purposes of offering the Services, issuing and holding
            securities, and advising and servicing LV-related funds, who may use
            the Private Information under and consistent with the terms of this
            Privacy Policy. If we are under a subpoena, court order,
            investigation, audit, reporting requirement or other legal or
            regulatory obligation, we may be required to disclose Private
            Information as a matter of legal or regulatory process to any local
            or foreign regulatory, governmental, tax, law enforcement or other
            agencies or authorities for the purposes of complying with any law,
            rule, regulation or guideline. <br></br>
            <br></br> Any affiliate, adviser, contractor, agent or third party
            service provider who provide us with services relating to
            administration, telecommunications, payment, clearing, audit,
            accounting, risk management, credit, legal, compliance, operations,
            sales and brokerage, marketing, relationship management, securities
            management, information technology, records and data storage,
            performance measurement and compilation and analysis in connection
            with our provision of the Site and Services and our operations. Such
            aggregated or anonymized data that does not identify you may be
            shared with the foregoing persons, among others, for purposes of
            marketing, research, and as part of our Services. Our service
            providers who provide us with security, fraud prevention, marketing
            technology, information technology, site analytics, site
            maintenance, software development, electronic signatures, identity
            verification and other Site and service features, provided that all
            such parties will be under confidentiality duties to protect and not
            share any Private Information we give them. Any affiliated custodial
            organizations in order to enroll you as their client. <br></br>
            <br></br>These organizations may have their own privacy policies and
            client agreements, which they will disclose to you at the time of
            your enrollment and participation. To third parties to whom you
            agree or ask us to disclose Private Information. Any other person
            under a duty of confidentiality to LV or affiliates that have
            undertaken to keep such Private Information confidential. Cookies
            When you access the Site or Services, we may place cookies, pixel
            tags, web beacons, page tags, or other data-gathering and
            transmitting tools or files on your device. These help us recognize
            you as a user, customize and personalize the Services, study and
            improve our offerings, collect information about your device and
            software in order to improve Site performance or prevent fraud. Most
            Internet browsers and other access software are able to disable or
            remove these cookies, and will contain instructions on how to do so,
            but doing so could affect your ability to use the Site and Services.
            Age restrictions The Site and Services are directed at and intended
            solely for adults according to the age of majority and other laws of
            their jurisdiction. We do not knowingly collect any information from
            anyone under the age of 18 or allow them to use the Site or
            Services. If you learn that we may have inadvertently collected any
            such information, please contact us immediately for a resolution.
            Other sites and services LV may refer you to third parties, via
            links from its Site or Services, communications from LV, or
            otherwise. <br></br>
            <br></br> These include among others any banks, payment processors,
            electronic signature services, brokerages, educational and
            informational sites, and transfer agents. LV is not responsible for
            the privacy policies and practices, performance, or terms of use and
            other contracts offered by these third parties, and does not
            endorse, provide, or provide any warranties with respect to any
            content or services they offer you. Please remember that when you go
            to another website or other service, that service may have its own
            privacy policy that applies in place of or in addition to ours. How
            we protect your Personal Information LV is committed to maintaining
            the confidentiality, accuracy, and security of Private Information,
            to honor the integrity and trust the community places in us, and for
            purposes of compliance. We protect Private Information by using
            software, physical safeguards, and internal procedures, in
            compliance with applicable laws and regulations. This includes using
            secure servers, encryption, and locked facilities as applicable. We
            maintain access policies designed to limit access to Private
            Information to those who need to know as part of the Services, and
            education and training for employees on the importance of
            confidentiality. We have a policy of requiring companies that work
            with us to protect Private Information as well. <br></br>
            <br></br> We ask you to help by taking reasonable precautions to
            protect your own files, use secure passwords, and generally exercise
            due care with any sensitive information about yourself or other
            people. No security system is completely without risk. If there is a
            security or data breach affecting your Private Information, we will
            attempt to investigate and as appropriate, notify you and take other
            applicable legal steps. Further, if you become aware that any
            Private Information we have appears to be inaccurate, you may
            contact us as described below. You may also contact us anytime if
            you have questions regarding your Private Information, the Services,
            or any other matter. Contacting Us LV is the “controller” of your
            Personal Information. LV is the contact for all matters related to
            this Privacy Policy, including without limitation, any complaints or
            requests for access to and correction of your Private Information.
            You may contact Gil Silberman, Managing Partner of LV, at 353
            Kearney Street, San Francisco CA 94108 USA, or legal@luca.vc. All
            non-US persons, including those in the European Union and
            Asia-Pacific, should use the above contact addresses for any
            questions, issues, or requests regarding their Private Information
            and this Privacy Policy. If you believe we have not adequately
            responded to any request or resolved any complaint, you may have the
            right to contact your local governmental or regulatory body to file
            a complaint. Policy Changes This Privacy Policy may be amended from
            time to time at any time. <br></br>
            <br></br> For the purposes of any such amendments, our posting of a
            revised version of the Privacy Policy, with an updated version
            number and/or date, is to be considered adequate notice to you of
            the amendment. Any revised version will be effective as of the time
            posted to the Site, or otherwise noticed to you. Therefore, you
            should review this Privacy Policy periodically. We may require you
            to affirmatively assent to any revised version of this Privacy
            Policy as a condition for continued use of the Site or Services or
            as required by relevant laws or regulations. Transfer of Personal
            Information Between Jurisdictions Our collection, holding,
            processing or use of your Private Information in accordance with
            this Privacy Policy may (whether in whole or in part): (i) take
            place outside of the jurisdiction in which you reside, or (ii) be
            controlled by a party whose principal place of business is located
            outside of your current jurisdiction, where there may not be in
            force any data protection law which is substantially similar to, or
            serves the same purposes as, the data protection laws in your
            current jurisdiction. This means that your Private Information may
            not be protected to the same or similar level as in your current
            jurisdiction. You understand and agree that your Private Information
            may be transferred outside of your current jurisdiction, where such
            jurisdiction which may or may not provide the same level of
            protection for personal data. Use of Private Information in Direct
            Marketing We do not intend to use your Private Information for
            direct marketing, and we will seek your written consent and provide
            all appropriate notices should we intend to do so.
          </p>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
export default Privacy;
