import React, { useState } from "react";
import "./Nav.css";
import NavItem from "./NavItem";
import logo from "../../resources/lv-graphic-logo-2.png";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const show = menuOpen ? "show" : "";

  return (
    <React.Fragment>
      <div className="nav-container">
        <nav className={"navbar navbar-expand-lg navbar-light custom-navbar"}>
          <a className="nav-link-logo" href="/">
            <img src={logo} className="nav-logo" />
            <h1 className="nav-company-name">Luca Ventures</h1>
          </a>

          <button
            className="navbar-toggler no-bs-border"
            type="button"
            onClick={() => toggleMenu()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={"collapse  navbar-collapse " + show}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto nav-item-link">
              {/* <NavItem path="/" name="Home" /> */}
              <NavItem
                className="nav-item-link"
                path="/work"
                name="Secondaries"
              />
              <NavItem
                className="nav-item-link"
                path="/venture-investments"
                name="Venture Investments"
              />
              <NavItem className="nav-item-link" path="/team" name="Team" />
              <NavItem
                className="nav-item-link"
                path="/contact"
                name="Contact"
              />
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Nav;
