import React, { useEffect, useState } from "react";
import "./LottieTextSection.css";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import "animate.css/animate.min.css";
import useWindowDimensions from "./useWindowDimensions";
import ScrollAnimation from "react-animate-on-scroll";
// const isMobile = false;

function LottieTextSection({
  title,
  textContent,
  lottieLeftBool,
  lottie,
  learnMoreLink,
  buttonTitle = "",
  color,
  lottieWidth = 280,
}) {
  // prettier-ignore
  const { height, width } = useWindowDimensions();

  const isMobile = width <= 600;

  return (
    <React.Fragment>
      <div
        style={{ backgroundColor: color }}
        className="position-relative overflow-hidden text-center text-light lottie-section-container"
      >
        <div className="row category-img-container-3">
          {lottieLeftBool && (
            <>
              <div className="lottie-section" style={{ overflow: "hidden" }}>
                <ScrollAnimation animateIn="slideInLeft" duration={1}>
                  {/* <div className="lottie-center"> */}
                  {isMobile ? (
                    <LottieAnimation src={lottie} width={width} />
                  ) : (
                    <LottieAnimation src={lottie} width={450} />
                  )}
                  {/* <LottieAnimation src={lottie} width={450} /> */}
                  {/* </div> */}
                </ScrollAnimation>
              </div>
              <div className="text-section">
                <ScrollAnimation animateIn="slideInRight" duration={1}>
                  <h2 className="about-job-header-1">{title}</h2>
                  <div className="about-description">{textContent}</div>
                  {learnMoreLink && (
                    <div>
                      <a href={learnMoreLink} className="get-in-touch-btn-1">
                        {buttonTitle !== "" ? buttonTitle : "Contact Us"}
                      </a>
                    </div>
                  )}
                </ScrollAnimation>
              </div>
            </>
          )}

          {!lottieLeftBool && isMobile && (
            <>
              <div className="lottie-section-left">
                <ScrollAnimation animateIn="slideInRight" duration={1}>
                  <LottieAnimation
                    src={lottie}
                    width={lottieWidth}
                    height={250}
                  />
                </ScrollAnimation>
              </div>
              <div className="text-section-left">
                <ScrollAnimation animateIn="slideInLeft" duration={1}>
                  <h2 className="about-job-header-1">{title}</h2>
                  <div className="about-description">{textContent}</div>
                  {learnMoreLink && (
                    <div>
                      <a href={learnMoreLink} className="get-in-touch-btn-1">
                        {buttonTitle !== "" ? buttonTitle : "Contact Us"}
                      </a>
                    </div>
                  )}
                </ScrollAnimation>
              </div>
            </>
          )}

          {!lottieLeftBool && !isMobile && (
            <>
              <div className="text-section-left">
                <ScrollAnimation animateIn="slideInLeft" duration={1}>
                  <h2 className="about-job-header-1">{title}</h2>
                  <div className="about-description">{textContent}</div>
                  {learnMoreLink && (
                    <div>
                      <a href={learnMoreLink} className="get-in-touch-btn-1">
                        {buttonTitle !== "" ? buttonTitle : "Contact Us"}
                      </a>
                    </div>
                  )}
                </ScrollAnimation>
              </div>

              <div className="lottie-section-left">
                <ScrollAnimation animateIn="slideInRight" duration={1}>
                  <LottieAnimation
                    src={lottie}
                    width={lottieWidth}
                    height={280}
                  />
                </ScrollAnimation>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default LottieTextSection;
